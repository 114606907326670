<template>
	<div class="trends">
		<div class="title">Your Score:</div>
		<div
				 class="bigScore right"
				 :class="{'good ': score.score >= 0, 'bad ': score.score < 0}">{{ score.score }} ({{ score.numCorrect }}/{{ score.numChoices }}) {{ score.percentage }}%</div>
		<div style='clear:both'>
			You've made {{ score.numChoices }} {{ score.numChoices == 1 ? 'choice' : 'choices' }} so far.
			You were correct about {{ score.percentage }}% of them for a score of {{ score.score > 0 ? '+' : '' }}{{ score.score }}.
		</div><br />
		<score-graph :scoreLines="scoreLines"></score-graph>
		<div class="divider">Your Influencers</div>
		<div v-for="graph in infGraphs" v-bind:key="graph.name" class="infRow">
			<div class="feedName" :style="{'color': graph.color}">{{ graph.name }}</div>
			<div
					 class="feedScore right"
					 :class="{'good ': graph.score.score >= 0, 'bad ': graph.score.score < 0}">{{ graph.score.score }} ({{ graph.score.numCorrect }}/{{ graph.score.numChoices }}) {{ graph.score.percentage }}%</div>
			<div style='clear:both'>You've made {{ graph.score.numChoices }} {{ graph.score.numChoices == 1 ? 'choice' : 'choices' }} in {{ graph.name }}
				and were correct about {{ graph.score.numCorrect }} of them for a score of {{ graph.score.score > 0 ? '+' : '' }}{{ graph.score.score }}.
				The {{ graph.influencers.length }} that influencers affected these {{ graph.score.numChoices }} choices were:
			</div>
			<influencer-graph :influencers="graph.influencers"></influencer-graph>

			<div style='clear:both'>
				In {{ graph.name }} your success rate for each {{ graph.actorTerm }} after {{ graph.score.numChoices }} choices is:
			</div>
			<actor-graph :feedID="graph.id"></actor-graph>
		</div>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import InfluencerGraph from "@/components/InfluencerGraph";
import ActorGraph from "@/components/ActorGraph";
import ScoreGraph from "@/components/ScoreGraph";

export default {
	name: "Trends",
	components: {
		ActorGraph,
		InfluencerGraph,
		ScoreGraph
	},
	data() {
		return {
			infGraphs: [],
			score: {},
			graph: {},
			scoreLines: []
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.scoreLines = JSON.parse(localStorage.getItem('trends-score-lines'));
			this.score = await this.$services.getOne("score", "");
			const allFeeds = await this.$services.get("feeds");
			console.log("All Feeds are:", allFeeds);
			const scoreFeeds = allFeeds.map(async (feed) => {
				feed.score = await this.$services.get(`score?feedID=${feed.id}`);
				const scores = await this.$services.get(`score?feedID=${feed.id}&daily`);
				if (scores.length > 0) {
					//Get the Influencers for their charts
					const data = await this.$services.get(`influencers/scored?feedID=${feed.id}`);
					const influencers = data.filter(influencer => influencer.numChoices > 0);
					const infChart = {id: feed.id, name: feed.name, color: feed.color, score: feed.score, actorTerm: feed.actorTerm, actorsTerm: feed.actorsTerm, influencers: influencers};
					this.infGraphs.push(infChart);
					this.infGraphs.sort((a, b) => a.name < b.name ? -1 : 1);
				}
				const retVal = {name: feed.name, color: feed.color, scores: scores};
				return retVal;
			});
			console.log("infGraphs are", this.infGraphs);
			Promise.all(scoreFeeds).then(res => {
				this.scoreLines = res;
				localStorage.setItem('trends-score-lines', JSON.stringify(this.scoreLines));
			})
		}
	}
}

</script>

<style scoped></style>

<template>
	<svg ref="canvas" width="100%" />
</template>

<script>
import * as d3 from 'd3';

export default {
	name: "ActorGraph",
	props: ["feedID"],
	data() {
		return {
			actors: [],
			width: 0
		};
	},
	watch: {
		feedID: function () {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.width = this.$el.clientWidth;
			this.actors = await this.$services.get(`actors/scored?feedID=${this.feedID}`);
			this.drawActorGraph();
		},
		goToActor: async function (actor) {
			if (this.$services.hasKey("Driller")) {
				this.$router.push(`/actor/${actor.id}`)
			}
		},
		drawActorGraph: async function () {
			if (this.actors && this.actors.length > 0) {
				const margin = {top: 22, right: 2, bottom: 0, left: 0};
				const width = this.width - margin.left - margin.right;
				const barHeight = 25;
				const height = (this.actors.length + 2) * barHeight;
				const maxValue = d3.max(this.actors, act => Math.abs(act.score));
				const x = d3.scaleLinear().domain([maxValue * -1, maxValue]).range([0, width]);

				
				const chart = d3.select(this.$refs.canvas);
				chart.selectAll("*").remove();
				
				chart.style("height", height).append('g')
					.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')').append('g');

				const xAxis = d3.axisTop(x);
				chart.append('g')
					.attr("class", "axisWhite")
					.call(xAxis);

				chart.selectAll()
					.data(this.actors).enter()
					.append('rect')
					.attr('width', d => x(Math.abs(d.score)) - x(0))
					.attr('height', barHeight - 2)
					.attr('fill', d => d.score > 0 ? "#00b300" : "#e85252")
					.attr('x', d => d.score > 0 ? x(0) : x(d.score))
					.attr('y', (d, i) => i * barHeight + 5)
					.on("click", d => this.goToActor(d));

				chart.selectAll()
					.data(this.actors).enter()
					.append('rect')
					.attr('src', d => d.imageURL)
					.attr('x', d => d.score > 0 ? x(d.score) - barHeight + 7 : x(d.score) - 5)
					.attr('y', (d, i) => i * barHeight + 5)
					.attr('width', barHeight - 2)
					.attr('height', barHeight - 2)
					.attr('fill', '#e6e6e6')

				chart.selectAll()
					.data(this.actors).enter()
					.append('image')
					.attr('src', d => d.imageURL)
					.attr('x', d => d.score > 0 ? x(d.score) - barHeight + 7 : x(d.score) - 5)
					.attr('y', (d, i) => i * barHeight + 5)
					.attr('width', barHeight - 2)
					.attr('height', barHeight - 2)
					.attr('href', d => d.imageURL)
					.on("click", d => this.goToActor(d));

				const textWidthCalc = d3.scaleLinear().domain([314, 408]).range([7, 14]); // From iOS 5 - 8 Plus
				chart.selectAll()
					.data(this.actors).enter()
					.append('text')
					.html(d => `${d.name}: ${(d.score > 0 ? '+' : '') + d.score} (${d.numCorrect}/${d.numChoices})`)
					.attr('fill', d => d.score > 0 ? "#00b300" : "#e85252")
					.attr('y', (d, i) => i * barHeight + 23)
					.attr('x', d => d.score > 0 ? 0 : x(0) + 3 + Math.max(0, barHeight - (width / 2 - x(d.score) + 7)))
					.attr('font-size', barHeight - 5)
					.attr('font-weight', 600)
					.attr('textLength', d => d.name.length < textWidthCalc(this.width) ? 0 : this.width / 2 - margin.left - 7)
					.attr('lengthAdjust', "spacingAndGlyphs")
					.on("click", d => this.goToActor(d));
			}
		}
	}
}
</script>

<style scoped>
.axisWhite line {
	stroke: white;
}

.axisWhite path {
	stroke: white;
}

.axisWhite text {
	fill: white;
}
</style>
